// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-galerie-js": () => import("./../src/pages/galerie.js" /* webpackChunkName: "component---src-pages-galerie-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-o-chate-js": () => import("./../src/pages/o-chate.js" /* webpackChunkName: "component---src-pages-o-chate-js" */),
  "component---src-pages-rezervace-js": () => import("./../src/pages/rezervace.js" /* webpackChunkName: "component---src-pages-rezervace-js" */),
  "component---src-pages-v-okoli-js": () => import("./../src/pages/v-okoli.js" /* webpackChunkName: "component---src-pages-v-okoli-js" */)
}

